














































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { ROUTE_NAMES } from "../routenames";
import { api as appApi } from "@/store/modules/app";
import { api as schemasApi, updateUiSchema } from "@/store/modules/schemas";

import VJsoneditor from "v-jsoneditor";
import VueFormJsonSchema from "vue-form-json-schema";

import {
  nullable,
  typeIsMultilingualText,
  tt2str,
  CollectionNames,
} from "@auditcloud/shared/lib/types/common";
import { MultilingualText } from "@auditcloud/shared/lib/schemas";

@Component({ components: { VJsoneditor, VueFormJsonSchema } })
export default class Configuration extends Vue {
  formDisabled = true;
  formReadonly = false;

  valid = true;
  formValid = true;
  tab = 0;
  ui: any = [];
  schema: any = {};
  data: any = {};

  options = {
    templates: [
      /*
          {
            text: "Person",
            title: "Insert a Person Node",
            className: "jsoneditor-type-object",
            field: "PersonTemplate",
            value: {
              firstName: "John",
              lastName: "Do",
              age: 28
            }
          },
          {
            text: "Address",
            title: "Insert a Address Node",
            field: "AddressTemplate",
            value: {
              street: "",
              city: "",
              state: "",
              "ZIP code": ""
            }
          } */
    ],
    mode: "code",
    modes: ["tree", "form", "code", "text"], // // v-if="$user.isAdmin()"
  };

  get currentBreadCrumb() {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: "Form-Schema-Editor",
        to: {
          name: ROUTE_NAMES.SCHEMA_FORM_EDIT,
          params: {
            segmentName: "app",
          },
        },
        activeclass: "",
      },
    ];
  }

  @Watch("currentBreadCrumb", { immediate: true })
  onBreadCrumbChanged() {
    this.setBreadCrumb(this.currentBreadCrumb);
    this.setMenuPanel();
  }

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: () => Promise<boolean>;

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  vfjsOptions!: TodoAny;
  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  get patchedUi() {
    const ui = (disabled: boolean, readonly: boolean = false) => {
      const locale = this.$i18n.locale;
      console.log("patchedUi:ui", locale);
      const i18nKeyResolver = key => {
        return this.$t(key).toString();
      };
      const multilingualTranslator = (mt: MultilingualText) => {
        return tt2str(mt, locale);
      };

      return updateUiSchema(
        this.ui,
        disabled,
        readonly,
        i18nKeyResolver,
        multilingualTranslator
      );
    };

    return ui(this.formDisabled, this.formReadonly);
  }

  mounted() {
    try {
      const formschema =
        window.localStorage.getItem("FORM_SCHEMA_EDITOR") ??
        '{ "schema": {}, "ui":[], "data": {}}';
      const { schema, data, ui } = JSON.parse(formschema);
      this.schema = schema;
      this.ui = ui;
      this.data = data;
    } catch (err) {
      console.error(err);
    }
  }

  store() {
    const formschema = { schema: this.schema, ui: this.ui, data: this.data };
    window.localStorage.setItem(
      "FORM_SCHEMA_EDITOR",
      JSON.stringify(formschema)
    );
  }

  beforeDestroy() {
    this.store();
  }

  onError(err: TodoAny) {
    console.error("AConfigEditor:onError", err);
    this.valid = false;
  }
  onInputUi(data: null | any) {
    this.valid = true;
    this.ui = data;
    this.store();
  }
  onInputSchema(data: null | any) {
    this.valid = true;
    this.schema = data;
    this.store();
  }
  onInputData(data: null | any) {
    this.valid = true;
    this.data = data;
    this.store();
  }

  change(e) {
    console.log('"change" event', e);
    this.data = e;
  }
  onValidated(isValid) {
    console.log('"validated" event', isValid);
    this.formValid = isValid;
  }
  onChangeState(e) {
    console.log('"change-state" event', e);
  }
}
